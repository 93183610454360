/* ==========================================================================
* Centralised functions for tooltip formatting
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/latest/
========================================================================== */
import dayjs from "dayjs";
import { CURRENCY_CONFIG } from "shared/currency/format";
import { createDisplayValue } from "shared/currency/currencies";


export const TOOLTIP_FORMAT_CURRENCY = (
  context, currency = CURRENCY_CONFIG.currency, precision = 2,
) => {
  let label = context.dataset.label || "";
  if (label) {
    label += ": ";
  }
  if (context.parsed.y !== null) {
    label += createDisplayValue(context.parsed.y, currency, precision);
  }
  return label;
};

export const TOOLTIP_FORMAT_CURRENCY_NO_LABEL = (context, currency = CURRENCY_CONFIG.currency) =>
  createDisplayValue(context.parsed.y, currency);

export const TOOLTIP_FORMAT_TITLE_TIMESTAMP = (context) => {
  const itemIndex = context[0].dataIndex;
  const timeStamp = context[0].dataset.data[itemIndex].x;
  return dayjs(timeStamp).format("D MMMM YYYY");
};
