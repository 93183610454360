function reloadFrameAction() {
  const target = this.getAttribute("target");

  const frame = document.getElementById(target);
  if (!frame) { return; }
  if (!frame.src && !frame.dataset.reloadUrl) {
    console.warn(`cannot reload frame ${target} without src or data-reload-url`);
    return;
  }

  frame.src ||= frame.dataset.reloadUrl;
  frame.reload();
}

export default reloadFrameAction;
