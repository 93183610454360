import getCustomPropertyByName from "./utilities";
/* ==========================================================================
* Application Typography settings
========================================================================== */

// Temporary solution for calculating the font size
// The custom properties for font size are in rems whereas charts need an integer
const getFontSize = () => {
  const fontStack = getCustomPropertyByName("--fe-font-stack");

  return fontStack.includes("Circular") ? 15 : 14;
};

export const BASE_FONT_SIZE = getFontSize();

export const BASE_FONT_FAMILY = getCustomPropertyByName("--fe-font-stack");
