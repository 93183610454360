import { Controller } from "@hotwired/stimulus";

/**
 * @memberof shared.form
 * @module DisableSubmitterController
 * @controller
 * @private
 *
 * @description Replaces rails-ujs behaviour of disabling the form submitter when the
 * button is clicked.
 *
 * This must not be used directly, see "disable_on_submit_controller" if you need to manually
 * disable button in a form.
*/
export default class DisableSubmitterController extends Controller {
  disable(evt) {
    if (evt.target.dataset.turbo !== "false") {
      this.dispatch("complete");
      return;
    }

    // we need to wait for a tick before disabling the button otherwise
    // any values for the button will not be sent in the request
    setTimeout(() => {
      if (evt.submitter && evt.submitter.dataset.turbo === "false") {
        this.#disableSubmitter(evt);
        this.#updateSubmitterText(evt);
        this.dispatch("complete");
      }
    }, 1);
  }

  #disableSubmitter(evt) {
    evt.submitter.disabled = true;
  }

  #updateSubmitterText(evt) {
    if (!evt.submitter.dataset.disableWith) { return; }

    const disableWith = evt.submitter.dataset.disableWith;
    if (evt.submitter.nodeName.toUpperCase() === "BUTTON") {
      evt.submitter.textContent = disableWith;
    } else {
      evt.submitter.value = disableWith;
    }
  }
}
