import { Controller } from "@hotwired/stimulus";
import { throttle } from "lodash";

import dynamicFormButton from "./dynamic_form/dynamic_form_button";
import MorphableForm from "./dynamic_form/morphable_form";
import DirtyTracker from "./dynamic_form/dirty_tracker";
import RestoreDynamicFormValues from "./dynamic_form/restore_dynamic_form_values";

/**
 * @memberof shared
 * @module DynamicFormV3Controller
 * @controller
 * @private
 * @description Use via the dynamic_form_frame_tag and dynamic_form_trigger
 * helper methods
 *
 * This controller powers dynamic forms that update state using server-side rendering.
 *
 * Input elements that should update the form must trigger the `update` action. This
 * will append a temporary button to the form and submit it to the server. The form
 * frame tag can be set to throttle updates to avoid spamming the server. e.g. radio
 * buttons are fast to select via the keyboard.
 *
 * By default, the form will be submitted to the url for the current page using the
 * form's current method. The server response must contain a Turbo Frame tag with a
 * matching ID to update the form.
 *
 * On response, the controller will update the form using Idiomorph. Only necessary
 * form changes are made while morphing. This keeps the process fast and improves
 * user experience by maintaining focus.
 *
 * The component employs dirty tracking to ensure updates made to the form during the
 * submission process are not lost.
 *
 * When fields are removed from the form, the value is saved internally. By default,
 * the value will be restored if the field is added back to the DOM.
 *
 * When using additional JS that holds page state within the form, the `dynamic-form:morphed`
 * event can be listened for and used to update areas after morphing has completed. This
 * has already been implemented in the onMatch controller, but other JS will need to
 * handle these cases separately.
 *
 * The component does not handle any error other than being logged out. Users should
 * implement error handling by listening for the `turbo:frame-missing` event.
 *
 * ## Data attribute options
 *
 * The behaviour of the dynamic form can be controlled via data attributes. While most
 * of these data attributes should be added to the trigger element via the helper method,
 * `data-dynamic-form-ignore-restore`, `data-dynamic-form-ignore-morph`, and
 * `data-dynamic-form-ignore-attributes` are added to individual fields as needed.
 *
 * - `data-dynamic-form-action`: change the path the form is submitted to
 * - `data-dynamic-form-method`: change the method used to submit the form
 * - `data-dynamic-form-turbo-frame`: target a different Turbo Frame to update
 * - `data-dynamic-form-ignore-restore`: discard and avoid restoring the value of a
 *    field that has been removed from the DOM
 * - `data-dynamic-form-ignore-morph`: prevent default behaviour of replacing unchanged
 *    child elements like for like. Useful for wrapping WYSIHTML editors whose initialisers
 *    do not tolerate being removed from the DOM and then re-added.
 * -  `data-dynamic-form-ignore-attributes`: ignore the given attributes on the element. Useful
 *     for maintaining front-end state. Attributes must be supplied as a valid JSON array.
 * -  `data-dynamic-form-dirty-id`: Specify the id to use for dirty tracking. This can be useful
 *    if the id of the input element changes during updates.
 *
 *
 * There is a how-to guide for using the component on Notion:
 * www.notion.so/freeagent/Using-the-DynamicForm-V3-component-58fc1296da844b618563b6049c838ca9
 */
export default class DynamicFormV3Controller extends Controller {
  static values = {
    throttle: { type: Boolean, default: false },
    url: { type: String, default: null },
  };

  initialize() {
    this.dirtyTracker = new DirtyTracker();
    this.restoreDynamicFormValues = new RestoreDynamicFormValues();
    this.morphableForm = new MorphableForm({
      restorer: this.restoreDynamicFormValues, dirtyFields: this.dirtyTracker,
    });
    if (this.throttleValue) {
      this.update = throttle(this.update, 300);
    }
  }

  disconnect() {
    if (this.update.cancel) {
      this.update.cancel();
    }
  }

  update({ target }) {
    this.dirtyTracker.start(this.element, target);
    const form = this.element.querySelector("form");
    const button = dynamicFormButton(target, this.urlValue);

    form.appendChild(button);
    button.click();

    button.remove();
  }

  submit(evt) {
    const fetchRequest = evt.detail.formSubmission.fetchRequest;
    const url = new URL(fetchRequest.url, window.location.origin);
    if (!url.searchParams.has("dynamic_form_trigger")) {
      this.#cancelCurrentRequest();
      return;
    }
    this.fetchRequest = fetchRequest;
    const formData = fetchRequest.body;
    this.restoreDynamicFormValues.addToFormData(formData);
  }

  #cancelCurrentRequest() {
    try {
      if (this.fetchRequest) {
        this.fetchRequest.cancel();
        this.fetchRequest = undefined;
      }
    } catch {
      // allow the submission to continue even
      // if we can't cancel the previous request
    }
  }

  morphContent(evt) {
    evt.detail.render = (currentElement, newElement) => {
      this.morphableForm.morph(currentElement, newElement);
      this.dirtyTracker.reset();
      setTimeout(() => {
        const event = new CustomEvent(
          "dynamic-form:morphed", { bubbles: true, cancelable: false },
        );
        this.element.dispatchEvent(event);
      }, 2);
    };
  }
}
