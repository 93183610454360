import formatCurrencyByLocale from "shared/currency/format";
import { TOOLTIP_FORMAT_CURRENCY_NO_LABEL } from "./core/chart_tooltip_formats";
import { ChartToolTipHelpers } from "./plugins/chartjs-plugin-custom-tooltip";

export const SHARED_CHART_OPTIONS = {
  animations: {
    radius: {
      duration: 0,
    },
  },
  elements: {
    point: {
      hoverRadius: 4,
      hoverBorderWidth: 2,
    },
    line: {
      borderWidth: 3,
    },
  },
  datasets: {
    bar: {
      categoryPercentage: 0.75,
      barPercentage: 0.8,
    },
    dashedBar: {
      categoryPercentage: 0.75,
      barPercentage: 0.8,
    },
    line: {
      lineTension: 0,
    },
  },
  scales: {
    y: {
      ticks: {
        maxTicksLimit: 6,
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: TOOLTIP_FORMAT_CURRENCY_NO_LABEL,
      },
    },
  },
};

export const SHARED_CHART_TYPES = {
  balance: {
    hover: {
      intersect: false,
    },
    plugins: {
      // requires chartjs-plugin-mouse-line. Required in HybridBarLineChartController.
      mouseLine: {
        enabled: true,
      },
      negativeLineColor: {
        enabled: true,
      },
    },
    scales: {
      x: {
        gridLines: {
          display: true,
          drawBorder: true,
          drawOnChartArea: false,
          offsetGridLines: false,
        },
        type: "time",
        time: {
          unit: "month",
          format: "MMM YYYY",
          displayFormats: {
            month: "MMM",
          },
        },
        offset: false,
        ticks: {
          autoSkip: false,
          maxRotation: 0,
          minRotation: 0,
        },
      },
      y: {
        beginAtZero: true,
        grid: {
          z: 1,
        },
      },
    },
  },
  "incoming-outgoing": {
    scales: {
      x: {
        ticks: {
          callback(_value, index) {
            // for some reason this is returning only an index and not labels.
            // get the label from the chart config

            const label = this.chart.config._config.data.labels[index];
            // strip the year that comes from the back end.
            return label.split(" ")[0];
          },
        },
      },
    },
    plugins: {
      tooltip: {
        mode: "index",
        position: "average",
        intersect: false,
        enabled: false, // This disables the default tooltip in favour of our custom one.
        external(context) {
          // Tooltip Element
          const canvasEl = context.chart.canvas;
          const tooltipEl = ChartToolTipHelpers.setupTooltip(canvasEl);
          const tooltipModel = context.tooltip;

          // Hide if no tooltip
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
          }

          // Set Text
          if (tooltipModel.body) {
            const titleLines = tooltipModel.title || [];
            let innerHtml = "";

            titleLines.forEach((title) => {
              innerHtml += ChartToolTipHelpers.title(new Date(title), title);
            });

            if (tooltipModel.footer.length) {
              const incomingOutgoingData = tooltipModel.footer[0];
              const netCashflow = Number(incomingOutgoingData.incoming || 0) +
                Number(incomingOutgoingData.incomingPredicted || 0) -
                Number(incomingOutgoingData.outgoing || 0) -
                Number(incomingOutgoingData.outgoingPredicted || 0);
              innerHtml += ChartToolTipHelpers.cashFlowOutput(
                incomingOutgoingData,
              );
              innerHtml +=
                `<p class="fe-ChartTooltip-label fe-u-marginTop--smaller">Net Cashflow:
                ${formatCurrencyByLocale({ amount: netCashflow })}
              </p>`;
            }
            tooltipEl.innerHTML = innerHtml;
          }

          const pos = ChartToolTipHelpers.getPositioningValues(this.chart.canvas);

          const leftPos = pos.x + (tooltipModel.caretX - (pos.width / 2));
          const topPos = pos.y - (pos.height / 2);

          ChartToolTipHelpers.showTooltip(tooltipEl, leftPos, topPos);
        },
        callbacks: {
          footer: (context) => {
            const incoming = ChartToolTipHelpers.getItemFromContext(context, 0);
            const outgoing = ChartToolTipHelpers.getItemFromContext(context, 1);
            return { incoming, outgoing };
          },
        },
      },
    },
  },
};
