
/* ==========================================================================
* Custom ChartJS Plugin to render "Hover Lines" in a chart.
* ChartJS Documentation v3 BETA
* https://www.chartjs.org/docs/latest/

* Usage

  Using an object named mouseLine within the chart options object.
  You are able to set the line width and color.

  mouseLine: {
    color: "#CCC",
    lineWidth: 1,
    enabled: true,
  },
========================================================================== */

import { Chart } from "chart.js";
import getCustomPropertyByName from "shared/design_tokens/utilities";

Chart.register({
  id: "mouseLine",
  afterEvent(chart) {
    const chartRef = chart;

    if (!chart.options.plugins.mouseLine.enabled) {
      return;
    }

    chartRef.options.plugins.mouseLine.x = false;

    if (chart._active.length) {
      chartRef.options.plugins.mouseLine.x = chart._active[0].element.x;
    }
  },
  afterDatasetsDraw(chart) {
    if (!chart.options.plugins.mouseLine) {
      return;
    }

    const ctx = chart.ctx;
    const chartArea = chart.chartArea;
    const x = chart.options.plugins.mouseLine.x || false;

    if (x) {
      ctx.save();
      ctx.strokeStyle = chart.options.plugins.mouseLine.color || getCustomPropertyByName("--fe-color-grey-5");
      ctx.lineWidth = chart.options.plugins.mouseLine.lineWidth || 1;
      ctx.beginPath();
      ctx.moveTo(chart.options.plugins.mouseLine.x, chartArea.bottom);
      ctx.lineTo(chart.options.plugins.mouseLine.x, chartArea.top);
      ctx.stroke();
      ctx.restore();
      chart.getActiveElements().forEach(el => el.element.draw(ctx));
    }
  },
});
